import { default as installWorkbox } from '@dreamworld/workbox-installer';
import { store } from '../redux/store.js';
import * as appActions from '../redux/app/actions.js';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';

const confirmUpdate = async () => {
  return new Promise((resolve, reject) => {
    console.log('confirmUpdate called');
    store.dispatch({ type: appActions.UPGRADE_AVAILABLE });

    ReduxUtils.subscribe(store, `app.upgradeStatus`, (status) => {
      if (status === 'CONFIRMED') {
        resolve();
      }
    })
  });
};

export default elNewVersionNotification => {
  installWorkbox({
    url: '/service-worker.js',
    confirmUpdate,
  });
};
