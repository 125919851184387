import * as actions from './actions';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_STATUS:
      if (action.playerCode) {
        state = { ...state, playerCode: action.playerCode };
      }

      return { ...state, status: action.status };

    case actions.DONE:
      return {
        ...state,
        status: action.payload.status,
        playerId: action.payload._id,
        playerCode: action.payload.code,
        authToken: action.payload.authToken,
        registrationRequestedAt: action.payload.registrationRequestedAt,
        registrationApprovedAt: action.payload.registrationApprovedAt,
      };

    case actions.RESET:
      return {};

    default:
      return state;
  }
};
