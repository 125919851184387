import { fork, put, delay, call, all, take, takeEvery, select, cancel } from 'redux-saga/effects';
import * as actions from './actions';
import isEqual from 'lodash-es/isEqual';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import { dialog } from '../router/selectors.js';
import { ROUTE_CHANGED, navigateDialog, setDialogParams, back } from '@dreamworld/router';
import { lastConnectRequest } from './selectors';

let lastDevices;

/**
 * Behaviours:
 * polls `nmcli device show` at interval of 3 seconds.
 * If any of the devices is in 'connecting' status, then poll interval is changed to 1 second.
 */
function* pollDevices() {
  try {
    while (true) {
      const devices = yield call(window.WifiManager.devices);
      // console.debug(`poll: devices`);
      if (!isEqual(devices, lastDevices)) {
        console.info(`pollDevices: devices changed.`, devices);
        yield put({ type: actions.DEVICES_CHANGED, value: devices });
        lastDevices = devices;
      }
      const ms = isAnyConnecting(devices) ? 1000 : 3000;
      // console.debug(`poll: delay ${ms}ms`);
      yield delay(ms);
    }
  } catch (e) {
    console.error('pollDevices: failed. error=', e);
  }
}

const isAnyConnecting = devices => {
  return devices?.wifi?.state === 'connecting' || devices?.ethernet?.state === 'connecting';
};

/**
 * Loads saved connection profiles & stores into state.
 */
function* loadConnectionProfiles() {
  try {
    console.info('loadConnectionProfiles invoked');
    const connections = yield call(window.WifiManager.getConnectionProfiles);
    yield put({ type: actions.CONNECTION_PROFILES_LOADED, connections });
  } catch (error) {
    console.error(`loadConnectionProfiles: Failed to load saved connection profiles. error=`, error);
  }
}

/**
 * Loads access-points through WifiManager & stores into state.
 */
function* loadAccessPoints() {
  try {
    // console.debug("loadAccessPoints: invoked");
    const accessPoints = yield call(window.WifiManager.getAccessPoints);
    yield put({ type: actions.ACCESS_POINTS_LOADED, accessPoints });
    // console.debug("loadAccessPoints: done");
  } catch (error) {
    console.error('loadAccessPoints: Failed to get access-points. error=', error);
  }
}

/**
 * On WIFI_SETTINGS dialog opened, starts polling of access-points & connection profiles.
 */
function* pollAccessPoints() {
  while (yield take([actions.WIFI_SETTINGS_DIALOG_OPENED])) {
    const task = yield fork(function* () {
      while (true) {
        const state = yield select();
        const connectReq = lastConnectRequest(state);
        if (connectReq?.status !== 'IN_PROGRESS') {
          //While connection request is in progress, many times acccessPoints result is empty
          yield call(loadAccessPoints);
        }
        yield delay(3000); // Reloads every 3 seconds
      }
    });

    yield take([actions.WIFI_SETTINGS_DIALOG_CLOSED]);
    yield cancel(task);
  }
}

let prevDialog;
function* routeChangeHandler() {
  const state = yield select();
  const currDialog = dialog(state).name;
  if (currDialog === 'WIFI_SETTINGS' && prevDialog !== 'WIFI_SETTINGS') {
    yield put({ type: actions.WIFI_SETTINGS_DIALOG_OPENED });
  } else if (currDialog !== 'WIFI_SETTINGS' && prevDialog === 'WIFI_SETTINGS') {
    yield put({ type: actions.WIFI_SETTINGS_DIALOG_CLOSED });
  }

  prevDialog = currDialog;
}

function* watchRouter() {
  yield call(routeChangeHandler);
  yield takeEvery(ROUTE_CHANGED, routeChangeHandler);
}

/*
 * It returns current wifi settings view. Possible values: `undefined`, `list`, `password` and `details`.
 * @returns {String}
 */
function* currentView() {
  const state = yield select();
  const dialogName = dialog(state).name;

  if (dialogName !== 'WIFI_SETTINGS') {
    return;
  }

  return dialog(state).params.view || 'list';
}

/**
 * It returns ssid associated with given connectionId. If not found, it returns given as it is.
 * @param {String} connectionId
 * @returns {String} ssid
 */
function* getSsidByConnectionId(connectionId) {
  //TODO: Move to selector (internal selector though)
  const state = yield select();
  const connections = get(state, 'networkManager.connections');
  let connection = find(connections, connection => connection.id === connectionId);

  if (connection) {
    return connection.ssid;
  }

  return connectionId;
}

/**
 * It's handler of CONNECT redux action.
 * It connects wifi connection of connectionId or ssid.
 * @param {Object} action
 */
function* onConnect({ connectionId, password }) {
  if (!connectionId) {
    throw new Error('connectionId | ssid is required for connect');
  }

  try {
    console.info(`connect: ssidOrConnectionId=${connectionId}, password=${password ? 'YES' : 'NO'}`);
    const ssid = yield call(getSsidByConnectionId, connectionId);

    yield call(window.WifiManager.connectProfile, ssid, password);
    yield call(loadAccessPoints);
    yield call(loadConnectionProfiles);
    yield put({ type: actions.CONNECT_DONE });

    // On successfull connect, when ask-password view is opened, shows connections list view.
    const view = yield call(currentView);
    if (view === 'password') {
      back();
    }
  } catch (err) {
    console.info('connect: failed. error=', err);

    const view = yield call(currentView);

    // Wrong SSID
    if (err && typeof err === 'string' && err.includes('No network with SSID')) {
      yield put({ type: actions.CONNECT_FAILED, error: 'SSID_NOT_FOUND' });
      if (view === 'password') {
        back();
      }
      return;
    }

    // Wrong Password
    yield put({ type: actions.CONNECT_FAILED, error: 'INVALID_PASSWORD' });

    if (view === 'list') {
      navigateDialog('WIFI_SETTINGS', { connectionId, view: 'password' });
    }
  }
}

/**
 * It's handler of FORGET redux action.
 * It removes saved wifi connection of given connectionId.
 * @param {Object} action
 */
function* onForget({ connectionId }) {
  if (!connectionId) {
    throw new Error('connectionId is required to forget connection');
  }

  const view = yield call(currentView);

  try {
    console.info(`forget: connectionId=${connectionId}`);
    yield call(window.WifiManager.removeProfile, connectionId);
    yield delay(1000);
    yield call(loadConnectionProfiles);
    yield put({ type: actions.FORGET_DONE });
    yield fork(loadAccessPoints);
    if (view === 'detail') {
      back();
    }
  } catch (err) {
    yield put({ type: actions.FORGET_FAILED, error: err });
    //TODO: Show error toast
    console.error('forget: failed. err=', err);
  }
}

function* onAskPassword({ ssid }) {
  setDialogParams({ connectionId: ssid, view: 'password' });
}

function* onCancel() {
  back();
}

function* onViewDetail({ connectionId }) {
  setDialogParams({ connectionId, view: 'detail' });
}

/**
 * Init Saga.
 */
export default function* saga() {
  yield fork(pollDevices);
  yield all([
    call(loadConnectionProfiles),
    call(loadAccessPoints),
    call(pollAccessPoints),
    call(watchRouter),
    takeEvery(actions.CONNECT, onConnect),
    takeEvery(actions.FORGET, onForget),
    takeEvery(actions.ASK_PASSWORD, onAskPassword),
    takeEvery(actions.VIEW_DETAIL, onViewDetail),
    takeEvery(actions.CANCEL, onCancel),
  ]);
}
