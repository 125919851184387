export const RESET = 'REGISTRATION_RESET';

/**
 * Interal action.
 */
export const UPDATE_STATUS = 'REGISTRATION_UPDATE_STATUS';

/**
 * Interal action. Dispatched when registration is successfully requested.
 */
export const DONE = 'REGISTRATION_DONE';

/**
 * It dispatched by UI to retry registration.
 */
export const RETRY = 'REGISTRATION_RETRY';

/**
 * It used by UI to retry registration from Reset required page.
 */
export const retryRegistration = () => {
  return {
    type: RETRY,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};
