import get from 'lodash-es/get';
import filter from 'lodash-es/filter';
import memoize from 'proxy-memoize';
import cloneDeep from 'lodash-es/cloneDeep';
import find from 'lodash-es/find';
import sortBy from 'lodash-es/sortBy';
import head from 'lodash-es/head';
import forEach from 'lodash-es/forEach';
import map from 'lodash-es/map';
import isEmpty from 'lodash-es/isEmpty';
import * as app from '../app';
import * as registrationSelectors from '../registration/selectors.js';
import { dbName } from '../app/selectors.js';

/********************** START: Videos Selectors **************************************/
/**
 * @param {Object} state Redux state
 * @returns {Object} All videos for current device. e.g. {$docId: $doc, ....}
 */
export const allVideos = memoize(state => {
  return filter(get(state, `dreamdb.couchdb-default.docs-${dbName(state)}`), doc => !doc._deleted && doc._id.startsWith('vd_'));
});

/**
 * @returns {Array} List of video ids (non deleted), sorted by it's time.
 *                  e.g. ['vd_wc7368yjcmK2kb2', ...]
 */
export const videoIds = memoize(state => {
  const videos = filter(get(state, `dreamdb.couchdb-default.docs-${dbName(state)}`), doc => doc._id.startsWith('vd_'));
  let list = cloneDeep(videos);
  list = filter(list, video => !video._deleted);
  forEach(list, video => {
    const _plays = plays({ state, videoId: video._id });
    video.firstPlayTime = get(head(_plays), 'time');
  });
  list = sortBy(list, 'firstPlayTime');
  return map(list, '_id');
});

/**
 * @returns {Object} video detail of given id
 */
export const videoDetail = memoize(({ state, videoId }) => {
  let detail = cloneDeep(get(state, `dreamdb.couchdb-default.docs-${dbName(state)}.${videoId}`));
  if (!detail) {
    return {};
  }

  detail.sizeInMB = `${(detail.size / (1024 * 1024)).toFixed(2)} MB`;
  return detail;
});
/********************** End: Videos Selectors **************************************/

/********************** START: Download Status Selectors **************************************/
/**
 * @returns {Array} List of all the download statuses.
 */
export const allVideoDownloadStatuses = state => {
  return filter(get(state, `dreamdb.couchdb-default.docs-${dbName(state)}`), doc => doc._id.startsWith('vds_'));
};

/**
 * Returns all VDS in UNKNOWN status.
 */
export const allUnknownVds = memoize(state => {
  return allVideoDownloadStatuses(state).filter(vds => !vds._deleted && vds.status === 'UNKNOWN');
});

/**
 * Returns all VDS which are deleted from the database, but file deletion is pending.
 */
export const pendingForDeletion = memoize(state => {
  const deletedVideos = state.deletedVideos;
  return allVideoDownloadStatuses(state).filter(vds => {
    const videoId = vds._id.replace('vds_', 'vd_');
    return vds._deleted === true && deletedVideos.indexOf(videoId) == -1;
  });
});

/**
 * @returns Object of video download status details
 */
export const videoDownloadStatus = memoize(({ state, videoId = '' }) => {
  const localStatus = videoDownloadStatusLocal({ state, videoId });
  const remoteStatus = videoDownloadStatusRemote({ state, videoId });
  const result = { ...remoteStatus, ...localStatus };
  return result;
});

/**
 * Only local video download status.
 */
export const videoDownloadStatusLocal = ({ state, videoId }) => {
  const downloadStatus = get(state, `downloadStatus`, {});
  return downloadStatus.videoId === videoId ? downloadStatus : {};
};

/**
 * Only remote download status.
 */
export const videoDownloadStatusRemote = ({ state, videoId }) => {
  const id = videoId.replace('vd_', 'vds_');
  return get(state, `dreamdb.couchdb-default.docs-${dbName(state)}.${id}`, {});
};

/**
 * @returns {Boolean} `true` when downloading is in progress or it's validation is in progress & it's started in current session.
 */
export const downloadInProgress = memoize(state => {
  const _allDownloadStatus = allVideoDownloadStatuses(state);
  const inProgressDownload = find(_allDownloadStatus, obj => obj.status === 'IN_PROGRESS' || obj.status === 'VALIDATING');
  const appBootTime = app.selectors.appBootTime(state);
  return !!(inProgressDownload && inProgressDownload.lastUpdatedAt >= appBootTime);
});

/**
 * @returns {Boolean} `true` when any of the video's download status is 'UNKNOWN'.
 */
export const isUnknownDownloadStatus = memoize(state => {
  const _allDownloadStatus = allVideoDownloadStatuses(state);
  return !!find(_allDownloadStatus, { status: 'UNKNOWN' });
});

/**
 * @returns {Boolean} `true` when any of the video's download is pending (not in COMPLETED status).
 */
export const isAnyPendingDownload = memoize(state => {
  const _allDownloadStatus = allVideoDownloadStatuses(state);
  return !!find(_allDownloadStatus, vds => vds.status !== 'COMPLETED' && vds.status !== 'ERROR');
});

/**
 * Returns a VideoDownloadStatus which is to be downloaded first.
 * - status is  PENDING, IN_PROGRESS or VALIDATING
 * - From all the pending videos, a video with nearest play-time is selected.
 * @param {*} state
 */
export const nextPending = state => {
  const localDownloadStatus = get(state, `downloadStatus`, {});
  let pendingVds = allVideoDownloadStatuses(state).map(vds =>
    vds._id === localDownloadStatus._id ? videoDownloadStatus({ state, videoId: vds._id.replace('vds_', 'vd_') }) : vds
  );
  pendingVds = pendingVds.filter(isDownloadPending).map(vds => {
    const videoId = vds._id.replace('vds_', 'vd_');
    return {
      vds,
      playTime: minPlayTime({ state, videoId: videoId }),
    };
  });
  return sortBy(pendingVds, 'playTime')[0]?.vds;
};

/**
 * Checks whethre download is pending for this VDS, based on the status?
 */
const isDownloadPending = vds => {
  return !vds._deleted && (vds.status === 'PENDING' || vds.status === 'IN_PROGRESS' || vds.status === 'VALIDATING');
};

/********************** END: Download status Selectors **************************************/

/********************** START: Play status Selectors **************************************/
/**
 * Finds neares playTime for the given videoId.
 */
const minPlayTime = ({ state, videoId }) => {
  return plays({ state, videoId })[0]?.time;
};

/**
 * @returns {Array} List of scheduls (non-deleted) of given videoId sorted by time
 */
export const plays = memoize(({ state, videoId }) => {
  const plays = allPlays(state);
  const videoPlays = filter(plays, play => play.videoId === videoId && !play._deleted);
  return sortBy(videoPlays, 'time');
});

/**
 * @param {Object} state Redux state
 * @returns {Object} All plays of current device. e.g. {$docId: $doc, ....}
 */
export const allPlays = memoize(state => {
  return filter(get(state, `dreamdb.couchdb-default.docs-${dbName(state)}`), doc => doc._id.startsWith('vps_'));
});

/**
 * @returns {Object} Play details of given videoId & Play status Id.
 */
export const playStatus = memoize(({ state, videoId, playStatusId }) => {
  const localStatus = playStatusLocal(state);
  const remoteStatus = playStatusRemote({ state, videoId, playStatusId });
  return { ...remoteStatus, ...localStatus };
});

/**
 * @returns {Object} play status from the local redux state.
 */
export const playStatusLocal = state => get(state, `playStatus`, {});

/**
 * @returns {Object} play status from the dreamdb.
 */
export const playStatusRemote = ({ state, videoId, playStatusId }) => {
  const _plays = plays({ state, videoId });
  return find(_plays, { _id: playStatusId }) || {};
};

/**
 *
 * @returns {Object} When download is pending, in-progress or failed, empty object.
 *                   Otherwise, next play or currently playing/paused play
 */
export const nextPlay = ({ state, videoId }) => {
  const list = plays({ state, videoId });
  const downloadStatus = videoDownloadStatus({ state, videoId });
  const video = videoDetail({ state, videoId });
  const config = app.selectors.config(state);
  const playBufferTime = get(config, 'playBufferTime');
  if (
    isEmpty(downloadStatus) ||
    isEmpty(video) ||
    downloadStatus.status === 'PENDING' ||
    downloadStatus.status === 'IN_PROGRESS' ||
    downloadStatus.status === 'ERROR'
  ) {
    return {};
  }

  const duration = video.duration * 1000;
  const currentTime = Date.now();

  return (
    find(list, item => {
      const playEndTime = item.time + duration + playBufferTime;
      return currentTime <= playEndTime && (item.status === 'PLAYING' || item.status === 'PAUSED' || item.status === 'PENDING');
    }) || {}
  );
};

/**
 * @returns {Boolean} `true` when any of the play of given video is pending.
 */
export const isAnyPlayPending = ({ state, videoId }) => {
  const _videoDetail = videoDetail({ state, videoId });
  const _plays = plays({ state, videoId });
  if (_plays.length == 0) {
    //As no plays are yet known, few plays will be scheduled soon (may be all db updates aren't yet received)
    return true;
  }
  const config = app.selectors.config(state);
  const playBufferTime = get(config, 'playBufferTime');
  let pending = false;

  forEach(_plays, play => {
    const status = play.status;
    const playStartTime = play.time;
    const duration = _videoDetail.duration * 1000;
    let playEndTime = playStartTime + duration + playBufferTime;
    let currentTime = Date.now();
    if ((status === 'PENDING' || status === 'PLAYING' || status === 'PAUSED') && currentTime < playEndTime) {
      pending = true;
      return false;
    }
  });
  return pending;
};

/**
 * @param {Object} state Redux state.
 * @returns {String} Auto play url. When play was stopped due to any reason.
 */
export const autoPlayUrl = state => {
  const _allPlays = allPlays(state);
  const config = app.selectors.config(state);
  const playBufferTime = get(config, 'playBufferTime');
  let playUrl = '';

  forEach(_allPlays, play => {
    if (play && (play.status === 'PLAYING' || play.status === 'PAUSED') && !play._deleted) {
      const videoId = play.videoId;
      const _videoDetail = videoDetail({ state, videoId });
      const _downloadStatus = videoDownloadStatus({ state, videoId });
      const currentTime = Date.now();
      const playStartTime = get(play, 'time', 0);
      const playEndTime = playStartTime + get(_videoDetail, 'duration', 0) * 1000 + playBufferTime;

      if (_downloadStatus.status === 'COMPLETED' && currentTime >= playStartTime && currentTime <= playEndTime) {
        playUrl = `/player/${videoId}/${play._id}`;
        return false;
      }
    }
  });

  return playUrl;
};

/**
 * @param {Object} state Redux state.
 * @param {String} videoId Video Id.
 * @returns {String} PLAYER page url of the play which is eligible for play at present.
 */
export const currentPlayUrl = ({ state, videoId }) => {
  const _plays = plays({ state, videoId });
  const config = app.selectors.config(state);
  const playBufferTime = get(config, 'playBufferTime');
  let playUrl = '';

  forEach(_plays, play => {
    if (play && play.status !== 'PLAYED' && !play._deleted) {
      const _videoDetail = videoDetail({ state, videoId });
      const _downloadStatus = videoDownloadStatus({ state, videoId });
      const currentTime = Date.now();
      const playStartTime = get(play, 'time', 0);
      const playEndTime = playStartTime + get(_videoDetail, 'duration', 0) * 1000 + playBufferTime;

      if (_downloadStatus.status === 'COMPLETED' && currentTime >= playStartTime && currentTime <= playEndTime) {
        playUrl = `/player/${videoId}/${play._id}`;
        return false;
      }
    }
  });

  return playUrl;
};

/**
 * @param {Object} state Redux state.
 * @returns {String} Current availalble play url. At present its used to play on `MediaPlayPause` key.
 */
export const currentAvailablePlayUrl = state => {
  const _allPlays = allPlays(state);
  const config = app.selectors.config(state);
  const playBufferTime = get(config, 'playBufferTime');
  let playUrl = '';

  forEach(_allPlays, play => {
    if (play && play.status !== 'COMPLETED' && !play._deleted) {
      const videoId = play.videoId;
      const _videoDetail = videoDetail({ state, videoId });
      const _downloadStatus = videoDownloadStatus({ state, videoId });
      const currentTime = Date.now();
      const playStartTime = get(play, 'time', 0);
      const playEndTime = playStartTime + get(_videoDetail, 'duration', 0) * 1000 + playBufferTime;

      if (_downloadStatus.status === 'COMPLETED' && currentTime >= playStartTime && currentTime <= playEndTime) {
        playUrl = `/player/${videoId}/${play._id}`;
        return false;
      }
    }
  });

  return playUrl;
};

/**
 * @param {Object} state Redux state.
 * @returns {String} First video's preview page url.
 */
export const firstPreviewUrl = state => {
  const _videoId = get(videoIds(state), '0', '');
  return _videoId && `/preview/${_videoId}`;
};

/**
 *
 * @param {Onject} state Redux state
 * @returns {Boolean} true if the videos is fetched from local, false otherwise.
 */
export const loading = state => {
  const playerId = registrationSelectors.playerId(state);
  const localInSync = get(state, `dreamdb.couchdb-default.queries-${playerId}.videos.metadata.localInSync`);
  return !localInSync;
};

/********************** START: Play status Selectors **************************************/
