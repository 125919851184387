/**
 * Internal action.
 */
export const DEVICES_CHANGED = 'NM_DEVICES_CHANGED';

export const WIFI_SETTINGS_DIALOG_OPENED = 'WIFI_SETTINGS_DIALOG_OPENED';
export const WIFI_SETTINGS_DIALOG_CLOSED = 'WIFI_SETTINGS_DIALOG_CLOSED';

export const ACCESS_POINTS_LOADED = 'NM_ACCESS_POINTS_LOADED';
export const CONNECTION_PROFILES_LOADED = 'NM_CONNECTION_PROFILES_LOADED';
/**
 * Dispatched by UI when user wants to connect new/existing connection or change password of saved connection.
 */
export const CONNECT = 'NM_CONNECT';

/**
 * Internal action.
 */
export const CONNECT_DONE = 'NM_CONNECT_DONE';

/**
 * Internal action.
 */
export const CONNECT_FAILED = 'NM_CONNECT_FAILED';

/**
 * Dispatched by UI when user wants to forget saved connection.
 */
export const FORGET = 'NM_FORGET';

/**
 * Internal action.
 */
export const FORGET_DONE = 'NM_FORGET_DONE';

/**
 * Internal action.
 */
export const FORGET_FAILED = 'NM_FORGET_FAILED';

export const ASK_PASSWORD = 'NM_ASK_PASSWORD';

export const CANCEL = 'NM_CANCEL';

export const VIEW_DETAIL = 'NM_VIEW_DETAIL';

/**
 * @param {String} connectionId connectionId | ssid
 * @param {String} password 
 */
export const connect = (connectionId, password) => {
  return {
    type: CONNECT,
    connectionId,
    password
  };
};

/**
 * @param {String} connectionId
 */
export const forget = (connectionId) => {
  return {
    type: FORGET,
    connectionId
  };
};

export const askPassword = (ssid) => {
  return {
    type: ASK_PASSWORD,
    ssid
  }
}

export const cancel = () => {
  return {
    type: CANCEL,
  }
}

export const viewDetail = (connectionId) => {
  return {
    type: VIEW_DETAIL,
    connectionId
  }
}
