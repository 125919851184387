import * as actions from './actions';
const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.CHANGE_LANGUAGE:
      return { ...state, language: action.language };
    case actions.CHANGE_TIMEZONE:
      return { ...state, timezone: action.timezone };
    default:
      return state;
  }
};
