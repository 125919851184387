import * as actions from './actions';
import { filter } from 'lodash-es';

const INITIAL_DOWNLOAD_STATE = {};
export const downloadStatus = (state = INITIAL_DOWNLOAD_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_DOWNLOAD_STATUS:
      state =  {...action.doc, videoId: action.doc._id.replace('vds_', 'vd_') };
      delete state._rev; //Dont' save _rev in state, as it causes conflict during next save
      return state;
    default:
      return state;
  }
};

export const deletedVideos = (state = [], action) => {
  switch (action.type) {
    case actions.UPDATE_DOWNLOAD_STATUS:
      const videoId = action.doc._id.replace('vds_', 'vd_');
      if (state.indexOf(videoId) !== -1) {
        return filter(state, (vdId) => vdId !== videoId);
      }
      return state;
    case actions.VIDEO_DELETED:
      return [...state, action.videoId];
    default:
      return state;
  }
}

const INITIAL_PLAY_STATE = {};
export const playStatus = (state = INITIAL_PLAY_STATE, action) => {
  const { videoId, playedDuration } = action;
  switch (action.type) {
    case actions.UPDATE_PLAYED_DURATION:
      return { ...state, playedDuration, status: 'PLAYING' };

    case actions.PLAY_PAUSED:
      return { ...state, videoId, status: 'PAUSED' };

    case actions.PLAY_COMPLETED:
      return { ...state, videoId, status: 'PLAYED' };

    case actions.RESET_LOCAL_PLAY_STATUS:
      return {};

    default:
      return state;
  }
};
