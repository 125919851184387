import { css } from 'lit';

const sharedStyles = css`
  * {
    user-select: none;
  }

  :host {
    display: block;
    box-sizing: border-box;
  }

  /* START: Typography  */
  .display1 {
    font-family: var(--display1-font);
    font-weight: var(--display1-weight);
    font-size: var(--display1-size);
    line-height: var(--display1-line-height);
    letter-spacing: var(--display1-tracking);
  }

  .display2,
  .display-large {
    font-family: var(--display2-font);
    font-weight: var(--display2-weight);
    font-size: var(--display2-size);
    line-height: var(--display2-line-height);
    letter-spacing: var(--display2-tracking);
  }

  .display3,
  .display-medium {
    font-family: var(--display3-font);
    font-weight: var(--display3-weight);
    font-size: var(--display3-size);
    line-height: var(--display3-line-height);
    letter-spacing: var(--display3-tracking);
  }

  .headline1,
  .display-small {
    font-family: var(--headline1-font);
    font-weight: var(--headline1-weight);
    font-size: var(--headline1-size);
    line-height: var(--headline1-line-height);
    letter-spacing: var(--headline1-tracking);
  }

  .headline2,
  .headline-large {
    font-family: var(--headline2-font);
    font-weight: var(--headline2-weight);
    font-size: var(--headline2-size);
    line-height: var(--headline2-line-height);
    letter-spacing: var(--headline2-tracking);
  }

  .headline3,
  .headline-medium {
    font-family: var(--headline3-font);
    font-weight: var(--headline3-weight);
    font-size: var(--headline3-size);
    line-height: var(--headline3-line-height);
    letter-spacing: var(--headline3-tracking);
  }

  .headline4,
  .headline-small {
    font-family: var(--headline4-font);
    font-weight: var(--headline4-weight);
    font-size: var(--headline4-size);
    line-height: var(--headline4-line-height);
    letter-spacing: var(--headline4-tracking);
  }

  .headline5,
  .title-large {
    font-family: var(--headline5-font);
    font-weight: var(--headline5-weight);
    font-size: var(--headline5-size);
    line-height: var(--headline5-line-height);
    letter-spacing: var(--headline5-tracking);
  }

  .headline6 {
    font-family: var(--headline6-font);
    font-weight: var(--headline6-weight);
    font-size: var(--headline6-size);
    line-height: var(--headline6-line-height);
    letter-spacing: var(--headline6-tracking);
  }

  .subhead1,
  .title-medium {
    font-family: var(--subhead1-font);
    font-weight: var(--subhead1-weight);
    font-size: var(--subhead1-size);
    line-height: var(--subhead1-line-height);
    letter-spacing: var(--subhead1-tracking);
  }

  .subhead2,
  .title-small {
    font-family: var(--subhead2-font);
    font-weight: var(--subhead2-weight);
    font-size: var(--subhead2-size);
    line-height: var(--subhead2-line-height);
    letter-spacing: var(--subhead2-tracking);
  }

  .body1,
  .body-large {
    font-family: var(--body1-font);
    font-weight: var(--body1-weight);
    font-size: var(--body1-size);
    line-height: var(--body1-line-height);
    letter-spacing: var(--body1-tracking);
  }

  .body2,
  .body-medium {
    font-family: var(--body2-font);
    font-weight: var(--body2-weight);
    font-size: var(--body2-size);
    line-height: var(--body2-line-height);
    letter-spacing: var(--body2-tracking);
  }

  .caption,
  .body-small {
    font-family: var(--caption-font);
    font-weight: var(--caption-weight);
    font-size: var(--caption-size);
    line-height: var(--caption-line-height);
    letter-spacing: var(--caption-tracking);
  }

  .button,
  .label-large {
    font-family: var(--button-font);
    font-weight: var(--button-weight);
    font-size: var(--button-size);
    line-height: var(--button-line-height);
    letter-spacing: var(--button-tracking);
  }

  .overline,
  .label-medium {
    font-family: var(--overline-font);
    font-weight: var(--overline-weight);
    font-size: var(--overline-size);
    line-height: var(--overline-line-height);
    letter-spacing: var(--overline-tracking);
  }

  /* END: Typography */

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default sharedStyles;
