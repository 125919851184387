import memoize from 'proxy-memoize';
import { selectors as dreamdbSelectors } from '@dw/dreamdb-client';
import { devices } from '../network-manager/selectors.js';
import { isAnyPendingDownload } from '../videos/selectors.js';

/**
 * Return value format: {status: "CONNECTED", blink: true}.
 * @param {*} state
 */
export const ledStatus = memoize(state => {
  const blink = isAnyPendingDownload(state);

  let status = 'NO_CONNECTION';
  const dreamdbConnected = dreamdbSelectors.connected(state, 'default');
  if (dreamdbConnected) {
    status = 'CONNECTED';
  } else {
    let _devices = devices(state);
    let wifiStatus = _devices?.wifi?.state || 'disconnected';
    let ethernetStatus = _devices?.ethernet?.state || 'disconnected';
    if (wifiStatus !== 'disconnected' || ethernetStatus !== 'disconnected') {
      status = 'NO_INTERNET';
    }
  }
  return { status, blink };
});
