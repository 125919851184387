import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import orderBy from 'lodash-es/orderBy';
import memoize from 'proxy-memoize';
import cloneDeep from 'lodash-es/cloneDeep';
import * as appSelectors from '../app/selectors.js';

export const devices = state => get(state, `networkManager.devices`);

export const ethernet = state => get(devices(state), 'ethernet') || {};

const _accessPoints = state => get(state, `networkManager.accessPoints`, []);

/**
 * @returns {ConnectionInfo[]} List of connections. computed from `access-points`, `connections` & `connectRequest`
 */
export const accessPoints = memoize(state => {
  const _list = cloneDeep(_accessPoints(state));
  const _connections = get(state, `networkManager.connections`, []);
  const _devices = devices(state) || {};
  const wifi = get(_devices, 'wifi', {});

  // console.log('device', device);

  let connectionBySSID = {};
  forEach(_connections, con => {
    connectionBySSID[con.ssid] = con;
  });

  let list = [];
  forEach(_list, item => {
    //nmcli shows such strange SSID, which isn't scanned by OS UI (Linux or Mac). So, let's remove them.
    //As a proper fix, this should be removed from electron-snap, but as a short-cut (hack) we removed
    //those accessPoints from here.
    if (item.ssid.trim() === '--') {
      return;
    }

    let _item = item;

    // Get connection
    const con = connectionBySSID[_item.ssid];
    if (con) {
      _item.connectionId = con.id;
      _item.saved = true;
    }

    if (_item.in_use) {
      if (con) {
        _item = { ..._item, ...wifi };
      } else {
        _item.in_use = false;
      }
    }

    // Get last connect request status.
    const _lastConnectRequest = lastConnectRequest(state);
    if (_lastConnectRequest.connectionId === _item.ssid || _lastConnectRequest.connectionId === _item.connectionId) {
      _item.connectionStatus = _lastConnectRequest.status;
      _item.connectionError = _lastConnectRequest.error;
    }

    list.push(_item);
  });

  return orderBy(list, ['in_use', 'saved', 'bars', 'ssid'], ['desc', 'asc', 'desc', 'desc']);
});

const connectionMap = {
  disconnected: 'NO_CONNECTION',
  connecting: 'CONNECTING',
  connected: 'CONNECTED',
};

/**
 * @returns {WifiStatus} e.g. {status: 'CONNECTED', bars: 3 }
 */
export const wifiStatus = memoize(state => {
  const bars = get(find(_accessPoints(state), ['in_use', true]), 'bars', 0);
  const _status = get(devices(state), 'wifi.state', 'disconnected');
  let status = connectionMap[_status];
  if (!appSelectors.connected(state)) {
    status = 'NO_INTERNET';
  }

  return { status, bars };
});

/**
 * Retrieved an AccessPoint by it's connectionId.
 */
export const connectionDetail = memoize(({ state, connectionId }) => {
  const _list = accessPoints(state);

  return find(_list, item => {
    return item.connectionId === connectionId || item.ssid === connectionId;
  });
});

/**
 *
 * @param {Object} state Redux state
 * @returns {Object} e.g. {connectionId, ssid, password, status, error}
 */
export const lastConnectRequest = state => get(state, `networkManager.connectRequest`, {});

/**
 * Represents last request sent for the forget/remove a connection.
 */
export const lastRemoveRequest = state => get(state, `networkManager.removeRequest`, {});
