const URLS = {
  pages: [
    {
      module: 'videos',
      name: 'HOME',
      pathPattern: '/',
    },
    {
      module: 'play-status',
      name: 'PLAY_STATUS',
      pathPattern: '/play-status/:videoId',
      pathParams: {
        videoId: String,
      },
    },
    {
      module: 'preview',
      name: 'PREVIEW',
      pathPattern: '/preview/:videoId',
      pathParams: {
        videoId: String,
      },
    },
    {
      module: 'player',
      name: 'PLAYER',
      pathPattern: '/player/:videoId/:playStatusId',
      pathParams: {
        videoId: String,
        playStatusId: String,
      },
    },
  ],

  dialogs: [
    {
      name: 'SETTINGS',
      pathPattern: '#settings/:view?',
      dialogParams: {
        view: String,
      },
    },
    {
      name: 'WIFI_SETTINGS',
      pathPattern: '#wifi-settings/:connectionId?/:view?',
      dialogParams: {
        connectionId: String,
        view: String,
      },
    },
    {
      name: 'ETHERNET',
      pathPattern: '#ethernet',
    },
  ],
};

export default URLS;
