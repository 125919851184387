export const MEDIA_PLAY_PAUSE = 'APP_MEDIA_PLAY_PAUSE';

export const UPGRADE_AVAILABLE = 'APP_UPGRADE_AVAILABLE';
export const UPGRADE = 'APP_UPGRADE';

/**
 * Internal action. It dispatched when it detects that the player is marked as `RESET`, `ARCHIVE` or `DESTROYED`.
 */
export const DREAMDB_INIT_DONE = 'APP_DREAMDB_INIT_DONE';


export const dreamdbInitDone = () => {
  return {
    type: DREAMDB_INIT_DONE
  };
};


export const upgrade = () => {
  return {
    type: UPGRADE
  };
};
