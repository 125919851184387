import { LitElement, html, css } from 'lit';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin';
import { init as initRouter, navigate, registerFallbackCallback } from '@dreamworld/router';
import URLS from '../redux/router/urls.js';
import { store } from '../redux/store.js';
import sharedStyles from '../styles/shared-styles.js';
import installSW from './install-sw.js';
import * as routerSelectors from '../redux/router/selectors.js';
import '../redux/network-status-indicator/index.js';

export class AppShell extends connect(store)(LitElement) {
  static get properties() {
    return {
      /**
       * Computed from router data.
       */
      _pageName: { type: String },
    };
  }

  constructor() {
    super();
    initRouter(URLS, store);
    registerFallbackCallback(this._routerFallbackCallback);
  }

  firstUpdated(changedProps) {
    super.firstUpdated(changedProps);
    installSW();
    import('./icons/smpv-icons.js');
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: flex;
          min-height: 100vh;
          background: var(--mdc-theme-background);
          color: var(--mdc-theme-text-primary-on-background);
          padding-left: 32px;
          padding-right: 32px;
        }
      `,
    ];
  }

  get _registrationPageTemplate() {
    if (this._pageName !== 'REGISTRATION') {
      return '';
    }

    return html`<registration-page></registration-page>`;
  }

  get _homePageTemplate() {
    if (this._pageName !== 'HOME') {
      return '';
    }

    return html`<home-page></home-page>`;
  }

  get _playStatusPageTemplate() {
    if (this._pageName !== 'PLAY_STATUS') {
      return '';
    }

    return html`<play-status-page></play-status-page>`;
  }

  get _previewPage() {
    if (this._pageName !== 'PREVIEW') {
      return '';
    }

    return html`<preview-page></preview-page>`;
  }

  get _playerPage() {
    if (this._pageName !== 'PLAYER') {
      return '';
    }

    return html`<player-page></player-page>`;
  }

  render() {
    return html`${this._registrationPageTemplate} ${this._homePageTemplate} ${this._playStatusPageTemplate} ${this._previewPage}
    ${this._playerPage}`;
  }

  willUpdate(changedProps) {
    super.willUpdate(changedProps);
    if (changedProps.has('_pageName')) {
      this.__loadFragment();
    }
  }

  async __loadFragment() {
    try {
      switch (this._pageName) {
        case 'REGISTRATION':
          await import('./registration-page.js');
          break;

        case 'HOME':
          await import('./home-page.js');
          break;

        case 'PLAY_STATUS':
          await import('./play-status-page.js');
          break;

        case 'PREVIEW':
          await import('./preview-page.js');
          break;

        case 'PLAYER':
          await import('./player-page.js');
          break;

        default:
          break;
      }
    } catch (err) {
      console.error(`Failed to import file for module`, err);
    }
  }

  /**
   * Navigates to home page when no router history found on router back.
   */
  _routerFallbackCallback() {
    navigate('/', true);
  }

  stateChanged(state) {
    this._pageName = routerSelectors.currentPageName(state);
  }
}

customElements.define('app-shell', AppShell);
