import get from 'lodash-es/get';
import memoize from 'proxy-memoize';

/**
 * @returns {Number} Application boot time in timestamp.
 */
export const appBootTime = state => get(state, `app.bootTime`);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when internet is connected.
 */
export const connected = state => get(state, `dreamdb.couchdb-default.connected`);

/**
 * @param {Object} state Redux state
 * @returns {Object} Application config.
 */
export const config = state => get(state, `app.config`);

/**
 * @param {Object} state Redux state
 * @returns {Object} upgrade status.
 */
 export const upgradeStatus = state => get(state, `app.upgradeStatus`);


 export const dreamdbInitDone = state => state.app.dreamdbInitDone;

 /**
 * @param {Object} state Redux state
 * @returns {String} Returns database name
 */
export const dbName = memoize(state => {
  const dbNamePrefix = get(state, `app.config.dbNamePrefix`);
  const playerId = get(state, `registration.playerId`);
  return dbNamePrefix + playerId;
});
