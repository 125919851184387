import * as actions from './actions';

const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.DEVICES_CHANGED:
      return { ...state, devices: action.value };

    case actions.ACCESS_POINTS_LOADED:
      return { ...state, accessPoints: action.accessPoints };
      
    case actions.CONNECTION_PROFILES_LOADED:
      return { ...state, connections: action.connections }

    case actions.CONNECT:
      return {
        ...state,
        connectRequest: {
          ...state.connectRequest,
          connectionId: action.connectionId,
          ssid: action.ssid,
          password: action.password,
          status: "IN_PROGRESS"
        }
      };

    case actions.CONNECT_DONE:
      return {
        ...state,
        connectRequest: {
          ...state.connectRequest,
          status: "DONE"
        }
      };

    case actions.CONNECT_FAILED:
      return {
        ...state,
        connectRequest: {
          ...state.connectRequest,
          status: "FAILED",
          error: action.error
        }
      };

    case actions.FORGET:
      return {
        ...state,
        removeRequest: {
          ...state.removeRequest,
          connectionId: action.connectionId,
          status: "IN_PROGRESS"
        }
      };
    
    case actions.FORGET_DONE:
      return {
        ...state,
        removeRequest: {
          ...state.removeRequest,
          status: "DONE"
        }
      };

    case actions.FORGET_FAILED:
      return {
        ...state,
        removeRequest: {
          ...state.removeRequest,
          status: "FAILED",
          error: action.error
        }
      };

    default:
      return state;
  }
};
