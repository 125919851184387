import get from 'lodash-es/get';
import memoize from 'proxy-memoize';

/**
 * It returns current registration status.
 * @param {Object} state
 * @returns {String} Possible values: `REGISTRATION_IN_PROGRESS`, `REGISTERED`, `RESET_REQUIRED`, `DESTROYED`, `ACTIVE`
 */
export const status = state => get(state, `registration.status`);

/**
 * Returns playerId
 * @param {Object} state
 * @return {String} playerId
 */
export const playerId = state => get(state, `registration.playerId`);

/**
 * Returns authToken
 * @param {Object} state
 * @returns {String} auth token
 */
export const authToken = state => get(state, `registration.authToken`);

/**
 * Returns playerCode
 * @param {Object} state
 * @returns {String} playerCode
 */
export const playerCode = state => get(state, `registration.playerCode`);

/**
 * @returns {String} Based on the current status of the registration & initial device setup, returns current view of the registration page.
 * Possible values: 'LANGUAGE', 'TIMEZONE', 'WIFI', 'REGISTRATION_IN_PROGRESS', 'REGISTERED' 'RESET_REQUIRED', 'DESTROYED'
 */
export const currentView = memoize(state => {
  return status(state);
});
