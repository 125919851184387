import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer';
import { persistEnhancer } from '@dw/pwa-helpers/redux-persist-enhancer';
import * as app from './app';
import * as registration from './registration';
import * as networkManager from './network-manager';
import * as settings from './settings';
import * as videos from './videos';

export const sagaMiddleware = createSagaMiddleware();

const persistOptions = [{ path: 'settings', sharedBetweenTabs: true }, { path: 'registration', sharedBetweenTabs: true }];

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: true,
    })
  : compose;

// Initializes the Redux store with a lazyReducerEnhancer (so that you can
// lazily add reducers after the store has been created) and redux-thunk (so
// that you can dispatch async actions). See the "Redux and state management"
// section of the wiki for more details:
// https://pwa-starter-kit.polymer-project.org/redux-and-state-management
export const store = createStore(
  state => state,
  devCompose(lazyReducerEnhancer(combineReducers), applyMiddleware(sagaMiddleware), persistEnhancer(persistOptions))
);

// Initially loaded reducers.
store.addReducers({
  settings: settings.reducers.default,
  networkManager: networkManager.reducers.default,
  registration: registration.reducers.default,
  app: app.reducers.default,
  downloadStatus: videos.reducers.downloadStatus,
  deletedVideos: videos.reducers.deletedVideos,
  playStatus: videos.reducers.playStatus,
});

//export store as global variable, so debugging through DevTools Console is possible if required
window.store = store;
window.networkManager = networkManager;

sagaMiddleware.run(app.saga.default);
sagaMiddleware.run(registration.saga.default);
sagaMiddleware.run(videos.saga.default);
sagaMiddleware.run(settings.saga.default);
sagaMiddleware.run(networkManager.saga.default);
