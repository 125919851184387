import { ledStatus } from './selectors';
import { store } from '../store';
import isEqual from 'lodash-es/isEqual';

let lastStatus;
console.log('network-status-indicator started');
store.subscribe(() => {
  const status = ledStatus(store.getState());
  if (isEqual(status, lastStatus)) {
    return;
  }

  lastStatus = status;
  console.log(`network-status-indicator status changed: status=${status.status}, blink=${status.blink}`);
  NetworkStatusIndicator.setStatus(status.status, status.blink);
});
